pre {
  overflow-x: auto;
  padding: 8px 12px; }
blockquote {
  padding-left: 2rem;
  margin-left: 1rem;
  border-left: 4px solid var(--quote-color);
  font-style: italic; }
iframe {
  width: 100%;
  height: 400px;
  border: none; }
