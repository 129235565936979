.date {
  color: var(--muted-color);
  font-size: 14px;
  margin-bottom: 0; }

.meta {
  & > * {
    margin-right: 2em; }

  a {
    &:hover {
      text-decoration: underline; } } }

.meta a,
.meta a:visited {
  color: var(--link-color);
  text-decoration: none; }

.title {
  margin-top: 0;
  margin-bottom: 0.2rem; }

