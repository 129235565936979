 :root {
  --brand-color: hsl(104, 100%, 19.6%);
  --link-color: hsl(104, 100%, 19.6%);
  --text-color: hsl(104, 15%, 19.6%);
  --footer-text-color: gray;
  --footer-separator-color: lightgray;
  --quote-color: hsl(104, 15%, 69.6%);
  --muted-color: hsl(103, 8.8%, 40%); }

@media (prefers-color-scheme: dark) {
  body {
    background: hsl(105, 15%, 10%); }
   :root {
    --link-color: hsl(104, 100%, 30%);
    --text-color: hsl(104, 15%, 95%);
    --muted-color: hsl(103, 8.8%, 50%);
    --footer-text-color: lightgray; } }


html, body, * {
  box-sizing: border-box; }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: var(--text-color);
  border-top: 3px solid var(--brand-color);
  margin: 0; }

h1,
h2 {
  font-weight: normal; }

h3 {
  font-weight: bolder; }

ul {
  padding: 0;

  li {
    list-style-type: none; } }

h2 {
  margin-top: 3rem;
  margin-bottom: 0.5rem; }

a {
  color: var(--link-color); }

p {
  margin: 2rem 0;
  line-height: 1.4em; }

.centered {
  display: block;
  width: 800px;
  margin: 0 auto;
  padding: 1rem; }

@media (max-width: 800px) {
  .centered {
    width: 100%; } }

.entry {
  margin-bottom: 3rem; }

