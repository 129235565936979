footer {
  padding: 1rem 0;
  margin: 4rem 0;
  &::before {
    width: 80px;
    content: "";
    display: block;
    border-top: 1px solid var(--footer-separator-color);
    margin-bottom: 1rem; }
  nav {
    display: flex;
    justify-content: space-between; } }
footer a,
footer a:visited {
  color: var(--footer-text-color);
  text-decoration: none; }
